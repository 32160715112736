function downloadFile (content, filename) {
  let a = document.createElement('a')
  let blob = new Blob([content])
  let url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(url)
}

function ajax (url, callback, options) {
  let xhr = new XMLHttpRequest()
  xhr.open('get', url, true)
  if (options.responseType) {
    xhr.responseType = options.responseType
  }
  xhr.onreadystatechange = function() {
    if (xhr.readyState === 4 && xhr.status === 200) {
      callback(xhr)
    }
  }
  xhr.send()
}

export function download (url, name) {
  ajax(url, function(xhr) {
    downloadFile(xhr.response, name)
  }, {
    responseType: 'blob'
  })
}

export function beforeUploadImage (file) {
  if (file.size > 1024 * 1024 * 10) {
    this.$message({
      message: '请上传大小不超过10M的图片',
      type: 'warning',
      duration: 2 * 1000,
      showClose: true,
    })
    return false
  }
  if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png') {
    this.$message({
      message: '请上传.jpg、.jpeg、.png格式的图片',
      type: 'warning',
      duration: 2 * 1000,
      showClose: true,
    })
    return false
  }
}

export function beforeUploadFile (file) {
  if (file.size > 1024 * 1024 * 20) {
    this.$message({
      message: '请上传大小不超过20M的文件',
      type: 'warning',
      duration: 2 * 1000,
      showClose: true,
    })
    return false
  }
}

export function goShopLogin (isLogin, url) {
  if (isLogin) {
    this.$router.push(url)
  } else {
    this.$router.push('/login')
  }
}

export function goAdminLogin (isLogin, status, url) {
  if (isLogin) {
    if (status === 0) {
      this.$confirm('您还未提交实名认证，是否提交?', '提示', {
        confirmButtonText: '提交',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.$router.push('/shop/realName')
      }).catch(() => {});
    } else if (status === 1) {
      this.$confirm('您还未提交企业认证，是否提交?', '提示', {
        confirmButtonText: '提交',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.$router.push('/admin/merchant/edit')
      }).catch(() => {});
    } else if (status === 4 || status === 7) {
      this.$confirm('您提交的企业认证未审核，是否查看?', '提示', {
        confirmButtonText: '查看',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.$router.push('/admin/merchant/edit')
      }).catch(() => {});
    } else if (status === 5) {
      this.$confirm('您提交的企业认证审核失败，是否查看?', '提示', {
        confirmButtonText: '查看',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.$router.push('/admin/merchant/edit')
      }).catch(() => {});
    } else {
      this.$router.push(url)
    }
  } else {
    this.$router.push('/login')
  }
}

export function toFixed (n, d) {
  let s = n + "";
  if (!d) d = 0;
  if (s.indexOf(".") == -1) s += ".";
  s += new Array(d + 1).join("0");
  if (new RegExp("^(-|\\+)?(\\d+(\\.\\d{0," + (d + 1) + "})?)\\d*$").test(s)) {
    let s = "0" + RegExp.$2, pm = RegExp.$1, a = RegExp.$3.length, b = true;
    if (a == d + 2) {
      a = s.match(/\d/g);
      if (parseInt(a[a.length - 1]) > 4) {
        for (let i = a.length - 2; i >= 0; i--) {
          a[i] = parseInt(a[i]) + 1;
          if (a[i] == 10) {
            a[i] = 0;
            b = i != 1;
          } else break;
        }
      }
      s = a.join("").replace(new RegExp("(\\d+)(\\d{" + d + "})\\d$"), "$1.$2");

    }
    if (b) s = s.substr(1);
    return (pm + s).replace(/\.$/, "");
  }
  return this + "";
}

export function bankNuLast(no) {
  if (no.length > 4) {
    return no.substr(no.length - 4, 4)
  } else {
    return no
  }
}
