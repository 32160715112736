<template>
    <div class="shop_main noMenu" style="background: #FFF">
        <div class="shop_order_main">
            <userMenu type="1" />

            <div class="shop_order_list">
                <div class="shop_order_upload_title">
                    <h1 class="title">付款凭证上传</h1>
                </div>

                <div class="shop_order_data mt40">
                    <div class="shop_order_table">
                        <div class="table_header">
                            <div class="item" style="flex: 3; text-align: center"><span>商品</span></div>
                            <div class="item" style="flex: 1; text-align: center"><span>单价</span></div>
                            <div class="item" style="flex: 1; text-align: center"><span>数量</span></div>
                            <div class="item" style="width: 120px; text-align: center"><span>订单金额</span></div>
                            <div class="item" style="width: 90px; text-align: center"><span>交易状态</span></div>
                        </div>

                        <div class="table_list">
                            <div class="table_item">
                                <div class="table_item_header">
                                    <div class="info">
                                        <div class="text">{{dataInfo.createdAt}}</div>
                                        <div class="text ml30">订单号：{{dataInfo.detailCode}}</div>
                                    </div>
                                    <el-button type="text" @click="$router.push({path: '/shop/order/detail', query: {id: dataInfo.id}})">详情</el-button>
                                </div>

                                <div class="table_item_body">
                                    <div class="item" style="flex: 5">
                                        <div class="goods_list" v-for="(item, index) in dataInfo.goodsInfo" :key="index">
                                            <div class="goods_list_item" style="flex: 3">
                                                <div class="goods_item">
                                                    <el-image :src="imageURL + item.goodsImageUrl" class="image" fit="contain"></el-image>
                                                    <div class="goods_item_info">
                                                        <div class="name">{{item.goodsName}}</div>
                                                        <div class="text">
                                                            <span class="mr16" v-for="(sku, j) in item.sku" :key="j">{{sku.skuKey}}：{{sku.skuValue}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="goods_list_item" style="flex: 1; text-align: center">
                                                <span class="desc">￥{{item.price}}</span>
                                            </div>
                                            <div class="goods_list_item" style="flex: 1; text-align: center">
                                                <span class="desc">×{{item.num}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item" style="width: 120px; text-align: center">
                                        <span class="desc">￥{{dataInfo.price}} <br /><span>(全额付款)</span></span>
                                    </div>
                                    <div class="item" style="width: 90px; text-align: center">
                                    <span class="desc">
                                        <div class="status red" v-if="dataInfo.status === 1">待付款</div>
                                        <div class="status yellow" v-if="dataInfo.status === 2">待发货</div>
                                        <div class="status blue" v-if="dataInfo.status === 3">已发货</div>
                                        <div class="status green" v-if="dataInfo.status === 4">已完成</div>
                                        <div class="status info" v-if="dataInfo.status === 5">已取消</div>
                                    </span>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div class="shop_form mt40">
                    <el-form ref="form" :model="form" label-position="top" size="small" :rules="rules">
                        <el-form-item label="审核信息：" class="mb32" v-if="dataInfo.payStatus === 3">
                            <span class="resText">{{dataInfo.payReason}}</span>
                        </el-form-item>

                        <el-form-item class="mb32" prop="url">
<!--                            <el-upload-->
<!--                                    class="upload-demo"-->
<!--                                    drag-->
<!--                                    :action="uploadURL"-->
<!--                                    :limit="1"-->
<!--                                    :before-upload="beforeUpload"-->
<!--                                    :on-success="imageUrlUpload"-->
<!--                                    :on-remove="imageUrlRemove">-->
<!--                                <i class="el-icon-folder-opened"></i>-->
<!--                                <div class="el-upload__text">点击或将文件拖拽到这里上传</div>-->
<!--                                <div class="el-upload__tip" >支持文件类型：jpg. pdf. jpe</div>-->
<!--                            </el-upload>-->
                            <el-upload
                                    :action="uploadURL"
                                    :before-upload="beforeUploadImage"
                                    :on-success="imageUrlUpload"
                                    :on-remove="imageUrlUploadRemove"
                                    list-type="picture-card"
                                    class="avatar"
                                    :file-list="imageFileList">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" :loading="isSubmitLoading" @click="submitForm('form')">提 交</el-button>
                            <el-button type="default" @click="$router.go(-1)">返 回</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {imageURL, uploadURL} from "../../../utils/config";
  import {beforeUploadImage} from "../../../utils/method";
  import {getOrdersInfo, toUploadOrder} from "../../../api/shop/orders";
  const userMenu = () => import('../../../components/layout/shop/userMenu')

  export default {
    name: "shopOrderUpload",
    data () {
      return {
        isRequestLoading: false,
        form: {
          url: [],
          type: 1
        },
        dataInfo: {},
        imageURL: imageURL,
        uploadURL: uploadURL,
        rules: {
          url: [
            {required: true, message: '请上传支付凭证', trigger: 'change'}
          ],
        },
        isSubmitLoading: false,
        imageFileList: [],
        beforeUploadImage: beforeUploadImage
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getInfo()
    },
    methods: {
      getInfo () {
        this.isRequestLoading = true
        getOrdersInfo(this.$route.query.id).then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            this.dataInfo = res.data
            if (res.data.payStatus === 3) {
              this.form.type = 2
              this.form.url = res.data.url

              let imageFileList = []
              res.data.url.forEach(item => {
                imageFileList.push({
                  url: imageURL + item,
                  name: imageURL + item,
                  response: {
                    data: item
                  }
                })
              })
              this.imageFileList = imageFileList
            }
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      imageUrlUpload (res) {
        if (res.code === 0) {
          this.form.url.push(res.data)
        }
      },
      imageUrlUploadRemove (file, fileList) {
        let data = []
        fileList.forEach(item => {
          data.push(item.response.data)
        })
        this.form.url = data
      },
      submitForm (formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.isSubmitLoading = true
            toUploadOrder(this.$route.query.id, {
              data: this.form
            }).then(res => {
              this.isSubmitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: '支付凭证上传成功',
                  type: 'success',
                  showClose: true,
                });
                this.$router.go(-1)
              }
            }).catch(() => {
              this.isSubmitLoading = false
            })
          }
        })
      }
    },
    components: {
      userMenu
    }
  }
</script>
