import {request} from '@/utils/request'

// 获取订单
export function getOrders(data) {
  return request('GET','/shop/orders', data, true)
}
// 获取订单
export function getOrdersInfo(id) {
  return request('POST','/shop/orders/info/' + id, '', true)
}
// 获取支付订单
export function getOrdersPay(id) {
  return request('POST','/shop/orders/logisticsAndPay/' + id, '', true)
}
// 取消订单
export function toCancelOrder(id) {
  return request('GET','/shop/orders/cancel/' + id, '', true)
}
// 上传支付凭证
export function toUploadOrder(id, data) {
  return request('POST','/shop/orders/' + id, data, true)
}
// 确认收货
export function toSendOrderSure(id) {
  return request('GET','/shop/orders/receipt/' + id, '', true)
}
// 修改合同
export function postContract(id, data) {
  return request('POST','/shop/orders/contractEdit/' + id, data, true)
}
// 日志
export function getLogs(data) {
  return request('GET','/shop/ordersLog', data, true)
}
